import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import AiService from '@/services/ai/ai.service.js'
import { Assistant } from '@/models/Ai/Assistant/Assistant.js'
import getEnv from '@/util/env'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import EventBus from '@/util/EventBus'
import SvgIcon from '@/components/vue-chat/components/SvgIcon/SvgIcon'
import { TextTag } from '@/classes/TextTag'
import AvailableTime from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/InboxConfig/AvailableTime/AvailableTime.vue'
import { AvailableTime as AvailableTimeModel } from '@/models/voice/AvailableTime'
import KnowledgeEditor from '@/components/Assistants/KnowledgeEditor/KnowledgeEditor.vue'

export default {
  name: 'NewAssistantEditor',
  components: {
    ColorPicker,
    ButtonMultimedia,
    SvgIcon,
    KnowledgeEditor,
    AvailableTime,
  },
  props: {
  },
  data () {
    return {
      assistant: new Assistant(),
      assistantClass: Assistant,
      showModalAssistant: false,
      text: '',
      runId: '',
      threadId: null,
      messages: [],
      saving: false,
      textColor: '#000',
      appereanceColor: '#000',
      creationData: {},
      basePath: '',
      assistantImageHelper: null,
      user: null,
      loadingResponse: false,
      textTag: null,
      showConfirmDontSaveChanges: false,
      multimediaShow: false,
      engines: [],
      hasCurrentUserCreatedWithMainApiKey: false,
      showChatbotPreview: false,
      loadingPreview: false,
      fromAssistantsTable: false,
      availableTimes: {
        times: [],
      },
      ready: false,
      savingPublish: false,
    }
  },
  methods: {
    setReady () {
      this.ready = false
      setTimeout(() => {
       this.ready = true
      })
    },

    backFromPreview () {
      if (this.fromAssistantsTable) {
        this.showModalAssistant = false
      }
      this.showChatbotPreview = false
    },

    enableDisableAssistant () {
      this.savingPublish = true
      const status = this.assistant.status === 1 ? 0 : 1
       AiService.changeStatus({ id: this.assistant.id, status: status }).then((assistantData) => {
        this.assistant.setData(assistantData)
        EventBus.$emit('updateAssistantFromTable', assistantData)
      }, (res) => {
         EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al actualizar al') + (status === 1 ? this.$t('Habilitar') : this.$t('deshabilitar')) + this.$t('del asistente.'))
      }).finally(() => {
        this.savingPublish = false
      })
    },

    addFormatText (type) {
      this.textTag = new TextTag(document.getElementById('welcomeMessageTextField'))
      const replaced = this.textTag.addFormatText(type)
      this.assistant.welcomeMessage = replaced || this.textTag.textElement.value
    },

    clearChatbotPreview () {
      this.messages = []
      this.createThreadWithParams()
    },

    async selectMultimediaFileAvatar (url, fileInfo) {
      this.assistant.picture = fileInfo.path + '/' + fileInfo.name + '.' + fileInfo.extension
      this.assistantImageHelper = this.basePath + 'multimedia-managers/file?path=' + this.assistant.picture + '&token=' + this.user.token
    },

    addFileToRemove (fileId) {
      this.assistant.fileIdsToRemove.push(fileId)
    },
    // eslint-disable-next-line complexity
    async selectMultimediaFile (url, fileInfo) {
      const selectedFileData = {
        name: fileInfo.name,
        serverUrl: url,
        path: fileInfo.path,
        extension: fileInfo.extension,
      }
      const found = this.assistant.filesToStore.filter((file) => file.name === selectedFileData.name).length
      if (found === 0) {
        this.assistant.filesToStore.push(selectedFileData)
      }
    },

    getMessages () {
      AiService.getMessages({ threadId: this.threadId }).then((res) => {
        console.log(res)
        this.loadingResponse = false
        this.messages = res.data
        AiService.checkLastMessageNotEnoughInfo({ threadId: this.threadId, assistantId: this.assistant.assistantId, assistantModelId: this.assistant.id })
      })
    },

    createThreadWithParams () {
      this.loadingPreview = true
      console.log('createThreadWithParams:assistant', this.assistant)
      AiService.createThreadWithParams({ assistantModelId: this.assistant.id }).then((res) => {
        this.threadId = res.id
        this.loadingPreview = false
        this.showChatbotPreview = true
      })
    },


    createMessageWithParams () {
      if (!this.text || this.text === '') return
      this.loadingResponse = true
      const text = this.text + ''
      this.text = ''
      AiService.createMessageWithParams({ text: text, threadId: this.threadId }).then((res) => {
        this.createRun()
        this.messages.push(res)
      })
    },

    getCreationData () {
      AiService.getCreationData().then((res) => {
        this.creationData = res
      })
    },

    getHasCurrentUserCreatedWithMainApiKey () {
      this.assistant.apiKeySource = 1
      // AiService.getHasCurrentUserCreatedWithMainApiKey().then((res) => {
      //   this.hasCurrentUserCreatedWithMainApiKey = res.hasCurrentUserCreatedWithMainApiKey
      //   this.assistant.apiKeySource = this.hasCurrentUserCreatedWithMainApiKey ? 2 : 1
      // })
    },

    createRun () {
      AiService.createRun({ threadId: this.threadId, assistantId: this.assistant.assistantId }).then((res) => {
        this.runId = res.id
        this.checkRunById()
      })
    },

    checkRunById () {
      AiService.checkRunById({ runId: this.runId, threadId: this.threadId }).then((res) => {
        if (res.status === 'failed') return

        if (res.status !== 'completed') {
         if (res.status === 'requires_action') {
             AiService.executeToolFunction({ assistantId: this.assistant.id, runId: this.runId, threadId: this.threadId, toolCalls: res.required_action.submit_tool_outputs.tool_calls }).then(() => {
              setTimeout(() => {
                this.checkRunById()
              }, 2000)
             })
          } else {
            setTimeout(() => {
              this.checkRunById()
            }, 2000)
          }
        } else {
          this.getMessages()
        }
      })
    },


    save () {
      this.saving = true
      this.assistant.availableTimes = JSON.stringify(this.availableTimes.times)
      AiService.save(this.assistant).then((res) => {
        this.assistant.setData(res.assistant)
      }, (res) => {
        console.log('res', res)
        if (res.response.status === 422) {
          if (res.response.data.errorFiles) {
            const fileNames = res.response.data.errorFiles.map(u => u.name + '.' + u.extension).join(', ')
            EventBus.$emit('showAlert', 'danger', this.$t('Se han guardado los datos, pero el proveedor del modelo para el asistente no soporta los archivos: ') + fileNames, 5000)
          }
          if (res.response.data.errorDeleteFiles) {
            const fileNames = res.response.data.errorDeleteFiles.join(', ')
            EventBus.$emit('showAlert', 'danger', this.$t('Se han guardado los datos, pero se produjo un problema con el borrado de los ficheros: ') + fileNames, 5000)
          }
        } else {
          EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un problema y no se pudo crear el asistente.'))
        }
      }).finally(() => {
        this.getHasCurrentUserCreatedWithMainApiKey()
        this.emitLoadAssistants()
        this.showModalAssistant = false
        this.saving = false
      })
    },

    emitLoadAssistants () {
      EventBus.$emit('gpt-assistants-load')
    },
    newAssistant () {
      this.assistant = new Assistant()
      this.showChatbotPreview = false
      this.assistant.apiKeySource = 1
      this.assistantImageHelper = null
      this.showModalAssistant = true
      this.messages = []
    },
    prepareEdit (item, showChatbotPreview = false, fromAssistantsTable = false) {
      this.fromAssistantsTable = fromAssistantsTable
      this.showChatbotPreview = showChatbotPreview
      this.assistant = new Assistant(item)
      this.availableTimes.times = AvailableTimeModel.parseAvailableTimes(this.assistant.availableTimes)
      this.assistant.apiKeySource = 1
      this.assistantImageHelper = this.basePath + 'multimedia-managers/file?path=' + this.assistant.picture + '&token=' + this.user.token

      if (this.showChatbotPreview) {
          this.clearChatbotPreview()
      }
      this.showModalAssistant = true
      setTimeout(() => {
        this.assistantCopyHelper = JSON.stringify(this.assistant)
      }, 300)
      this.setReady()
    },

    checkCloseDrawer () {
      if (this.existEditDifference()) {
        this.showConfirmDontSaveChanges = true
      } else {
        this.showModalAssistant = false
      }
    },

    confirmExitWithoutSave () {
      this.showModalAssistant = false
      this.showConfirmDontSaveChanges = false
    },

    existEditDifference () {
      return JSON.stringify(this.assistant) !== this.assistantCopyHelper
    },

    checkESCKey (event) {
      if (this.multimediaShow) return
      if (event.code === 'Escape' && this.showModalAssistant) {
        this.checkCloseDrawer()
      }
    },
    checkClickOutside (event) {
      if (this.multimediaShow) return
      const drawer = document.getElementById('assistantEditorDrawer')
      if (drawer) {
        const bounds = drawer.getBoundingClientRect()
        if (event.x < bounds.x && this.showModalAssistant) {
          this.checkCloseDrawer()
        }
      }
    },
    prepareScript () {
      this.htmlString = this.getScriptCode()
      this.copyScriptDialog = true
    },

    getScriptCode () {
      const origin = window.origin

      return `
    <script>
      if (!document.getElementsByClassName('nrs-web-chat-container').length) {
        const chatContainer = document.createElement("div");
        chatContainer.className = "nrs-web-chat-container";
        chatContainer.style.position = "fixed";
        chatContainer.style.bottom = "0";
        chatContainer.style.right = "0";
        chatContainer.style.paddingBottom = "0px";
        chatContainer.style.paddingRight = "15px";
        chatContainer.style.height = "50px";
        chatContainer.closedHeight = "50px";
        chatContainer.openHeight = "470px";
        window.addEventListener("message",e=>{"open-webchat"===e.data?chatContainer.style.height=chatContainer.openHeight:"close-webchat"===e.data&&(chatContainer.style.height=chatContainer.closedHeight)});
    
        const config = encodeURIComponent(JSON.stringify({}))

        const iframe = document.createElement("iframe");
        iframe.src = "${origin}/#/web-chat/chat?initiKey=${this.assistant.uniqueId}&config="+config
        iframe.frameBorder = "0";
        iframe.height = chatContainer.openHeight;
        iframe.width = '100%'
        iframe.allowtransparency = "true";
    
        chatContainer.appendChild(iframe);
    
        document.body.appendChild(chatContainer);
      }
    </script>
      `
    },
    copyChatbotCodeToClipboard () {
      navigator.clipboard.writeText(this.getScriptCode())
      EventBus.$emit('showAlert', 'success', this.$t('Se ha copiado el codigo al portapapeles'))
    },
    updateMultimediaShowStatus (value) {
      this.multimediaShow = value
    },
  },
  computed: {
    selectedEngine () {
      const foundEngine = this.creationData.engines.find((engine) => { return engine.id === this.assistant.engineId })
      return foundEngine ? foundEngine.engine : null
    },
    canWebchat () {
      return this.$store.getters['auth/getUser'].enable?.wchat
    },
  },
  watch: { },
  mounted () {
    EventBus.$on('gpt-assistants-create', this.newAssistant)
    EventBus.$on('gpt-assistants-edit', this.prepareEdit)
    EventBus.$on('MultimediaShowValue', this.updateMultimediaShowStatus)
    this.setReady()
  },
  created () {
    this.getCreationData()
    this.user = JSON.parse(localStorage.getItem('user'))
    this.basePath = getEnv('VUE_APP_API_URL')

    window.addEventListener('keydown', this.checkESCKey)
    document.body.addEventListener('click', this.checkClickOutside, true)
    this.getHasCurrentUserCreatedWithMainApiKey()
  },
  destroyed () {
    window.removeEventListener('keydown', this.checkESCKey)
    document.body.removeEventListener('click', this.checkClickOutside)
  },

}
