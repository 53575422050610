import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import AiService from '@/services/ai/ai.service.js'
import { Assistant } from '@/models/Ai/Assistant/Assistant.js'
import EventBus from '@/util/EventBus'
import SvgIcon from '@/components/vue-chat/components/SvgIcon/SvgIcon'
import NewAssistantEditor from '@/components/Assistants/NewAssistantEditor.vue'
import { Agent } from '@/classes/chat/agent'
import Contacts from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/Contacts/Contacts.vue'
import WebChatContact from '@/components/WebChat/Contacts/Contacts.vue'
import RoutesService from '@/services/whatsapp/routes.service'
import KnowledgeEditor from '@/components/Assistants/KnowledgeEditor/KnowledgeEditor.vue'
import { component as VueCodeHighlight } from 'vue-code-highlight'
import 'vue-code-highlight/themes/window.css'
import 'vue-code-highlight/themes/duotone-sea.css'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import AssistantsModal from './AssistantsModal/AssistantsModal.vue'
import userService from '../../services/user.service'

export default {
  name: 'Assistants',
  components: {
    Contacts,
    HeaderTopDashboard,
    ButtonMultimedia,
    NewAssistantEditor,
    SvgIcon,
    WebChatContact,
    KnowledgeEditor,
    VueCodeHighlight,
    AssistantsModal,
  },
  props: {
    showWarning: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      assistant: new Assistant(),
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      debouncedInput: '',
      timeout: null,
      showModalAssistant: false,
      confirmRemoveAssistant: false,
      dialogAssistantAssignment: false,
      agents: [],
      agentToAssignTo: null,
      agentsForSelector: [],
      contacts: [],
      selectedChannel: { channel: null },
      selectedWebChatContacts: [],
      action: '',
      assistantTotalContacts: {
        total: 0,
        webChat: 0,
        whatsAppTotal: 0,
      },
      dialogConfirmRemoveAssistantFromReassignment: false,
      routes: {
        list: [],
      },
      selectedUserMsisdnConfig: '',
      contactSelection: {
        contactIds: [],
        allItemsSelected: false,
        selectedAction: 1,
        selectedUserMsisdn: '',
        filters: {},
      },
      loading: false,
      selectedMethod: null,
      knowledgeDialog: false,
      renderKnowlegComponent: false,
      htmlString: '',
      copyScriptDialog: false,
      showAssistantsModal: true,
      showAssistantsProfile: false,
    }
  },
  methods: {
    closeAssistantsModal () {
      this.$router.push({ name: 'Home' }).catch(() => {})
    },
    closeAssistantsModalForever () {
      userService.setShowAssistantsProfileByUser({ showAssistantsProfile: false }).then(() => {
        this.changeUserShowAiProfile(false)
        this.showAssistantsModal = false
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al cerrar el dialogo').toString())
      })
    },
    loadShowProfiles () {
      const user = JSON.parse(localStorage.getItem('user'))
      this.showAssistantsProfile = user.showAssistantsProfile ?? true
    },
    changeUserShowAiProfile (showAssistantsProfile) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.showAssistantsProfile = showAssistantsProfile
      localStorage.setItem('user', JSON.stringify(user))
    },
    toggleAssistantsModal () {
      this.showAssistantsModal = !this.showAssistantsModal
    },

    updateAssistanInTable (assistantObject) {
      console.log(assistantObject)
      const items = []
      this.items.forEach((assistant) => {
        let assistantToAdd = assistant
        if (assistant.id === assistantObject.id) {
          assistantToAdd = assistantObject
        }
        items.push(assistantToAdd)
      })
      this.items = items
    },

    prepareScript (item) {
      this.assistant = new Assistant(item)
      this.htmlString = this.getScriptCode()
      this.copyScriptDialog = true
    },

    getScriptCode () {
      const origin = window.origin

      return `
    <script>
      if (!document.getElementsByClassName('nrs-web-chat-container').length) {
        const chatContainer = document.createElement("div");
        chatContainer.className = "nrs-web-chat-container";
        chatContainer.style.position = "fixed";
        chatContainer.style.bottom = "0";
        chatContainer.style.right = "0";
        chatContainer.style.paddingBottom = "0px";
        chatContainer.style.paddingRight = "15px";
        chatContainer.style.height = "50px";
        chatContainer.closedHeight = "50px";
        chatContainer.openHeight = "470px";
        window.addEventListener("message",e=>{"open-webchat"===e.data?chatContainer.style.height=chatContainer.openHeight:"close-webchat"===e.data&&(chatContainer.style.height=chatContainer.closedHeight)});
    
        const config = encodeURIComponent(JSON.stringify({}))

        const iframe = document.createElement("iframe");
        iframe.src = "${origin}/#/web-chat/chat?initiKey=${this.assistant.uniqueId}&config="+config
        iframe.frameBorder = "0";
        iframe.height = chatContainer.openHeight;
        iframe.width = '100%'
        iframe.allowtransparency = "true";
    
        chatContainer.appendChild(iframe);
    
        document.body.appendChild(chatContainer);
      }
    </script>
      `
    },
    copyChatbotCodeToClipboard () {
      navigator.clipboard.writeText(this.getScriptCode())
      EventBus.$emit('showAlert', 'success', this.$t('Se ha copiado el codigo al portapapeles'))
    },
    openKnowledgeDialog (item) {
      this.assistant = new Assistant(item)
      this.renderKnowlegComponent = false
      setTimeout(() => {
        this.renderKnowlegComponent = true
      })
      this.knowledgeDialog = true
    },
    closeAssistantAssignmentDialog () {
      this.dialogAssistantAssignment = false
      this.$refs.whatsappContactsComponent.selectedContacts = []
      this.$refs.webContactsComponent.selectedContacts = []
      this.selectedMethod = null
      this.agentToAssignTo = null
    },
    updateFilter (filters) {
      this.contactSelection.filters = filters
      console.log(this.contactSelection)
    },
    getCurrentUserRoutes () {
      RoutesService.getCurrentUserRoutes()
          .then((routes) => {
            this.routes.list = routes
            if (routes.length) {
              this.selectedUserMsisdnConfig = this.routes.list[0].msisdn
              this.contactSelection.selectedUserMsisdn = this.routes.list[0].msisdn
            }
          })
    },
    reAssignContacts () {
      this.loading = true
      const agentOrigin = this.agents.filter((agent) => agent.id === this.assistant.id && agent.typeId === 1)[0]
      AiService.reAssignContacts({
        agent: agentOrigin,
        agentToAssignTo: this.agentToAssignTo,
        contactSelection: this.contactSelection,
        channel: this.selectedChannel.channel,
      }).then((res) => {
        this.loading = false
        EventBus.$emit('showAlert', 'success', this.$t('Se han reasignado los contactos'))
        AiService.totalContactsByAssistantId({ assistantId: this.assistant.id }).then((res) => {
          this.assistantTotalContacts = res
        })

        if (this.selectedChannel.channel === 'whatsapp') {
          this.$refs.whatsappContactsComponent.deselectAll()
        }
        if (this.selectedChannel.channel === 'web-chat') {
          this.$refs.webContactsComponent.deselectAll()
        }
        this.loadContacts()
        this.getAssistants()
        this.agentToAssignTo = null
        this.contactSelection.contactIds = []
        this.contactSelection.selectedAction = 1
        this.contactSelection.allItemsSelected = false
      })
    },
    loadContacts () {
      console.log(this.selectedChannel.channel)
        setTimeout(() => {
          if (this.selectedChannel.channel === 'whatsapp' && this.$refs.whatsappContactsComponent) {
            this.$refs.whatsappContactsComponent.initTable()
            this.$refs.whatsappContactsComponent.getData()
          }
          if (this.selectedChannel.channel === 'web-chat' && this.$refs.webContactsComponent) {
            this.$refs.webContactsComponent.initTable()
            this.$refs.webContactsComponent.getData()
          }
        }, 200)
    },
    prepareAssistantAssignment (item = null, action = 'reassignment') {
      if (item) {
        this.assistant = new Assistant(item)
      }
      this.action = action
      this.agentsForSelector = this.agents.filter((agent) => {
        return agent.typeId === 0 || agent.id !== this.assistant.id
      })
      this.selectedChannel.channel = this.channels[0].value
      AiService.totalContactsByAssistantId({ assistantId: this.assistant.id }).then((res) => {
        this.assistantTotalContacts = res
      })
      this.dialogAssistantAssignment = true
      this.loadContacts()
    },

    newAssistant () {
      EventBus.$emit('gpt-assistants-create')
    },

    prepareEdit (item) {
      this.action = 'edit'
      EventBus.$emit('gpt-assistants-edit', item)
    },


    prepareEditAndShowBotPreview (item) {
      this.action = 'edit'
      EventBus.$emit('gpt-assistants-edit', item, true, true)
    },


    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
      ? this.options.page - 1
      : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getAssistants () {
      const data = this.getParams()
      this.disabledPagination = true
      AiService.getAssistants(data).then((response) => {
        this.items = response.data
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
      })
    },
    preRemoveAssistantFromReassignment () {
      this.dialogConfirmRemoveAssistantFromReassignment = true
    },
    preRemoveAssistant (item) {
      this.action = 'delete'
      this.assistant = new Assistant(item)
      AiService.totalContactsByAssistantId({ assistantId: this.assistant.id }).then((res) => {
        this.assistantTotalContacts = res
        if (this.assistantTotalContacts.total > 0) {
          this.prepareAssistantAssignment(null, this.action)
        } else {
          this.confirmRemoveAssistant = true
        }
      })
    },
    deleteAssistant () {
      AiService.deleteAssitant({ assistantId: this.assistant.id, assignRemainingToUser: this.assistantTotalContacts.total > 0 }).then(() => {
        this.assistant = new Assistant()
        this.confirmRemoveAssistant = false
        this.dialogConfirmRemoveAssistantFromReassignment = false
        this.dialogAssistantAssignment = false
        this.getAssistants()
        EventBus.$emit('showAlert', 'success', this.$t('Se ha eliminado el asistente.'))
        this.$refs.assistantEditorComponent.getHasCurrentUserCreatedWithMainApiKey()
      })
    },
  },
  computed: {
    methods () {
      return [
        { value: 'set_agent', text: this.$t('Asignar a agente').toString() },
      ]
    },
    canWebChat () {
      return this.$store.getters['auth/getUser'].enable?.wchat
    },
    actionOptions () {
      return [
        { text: this.$t('Seleccionados').toString(), value: 1 },
        { text: this.$t('No seleccionados').toString(), value: 2 },
        { text: this.$t('Todos (Sin tener en cuenta la paginación)').toString(), value: 3 },
      ]
    },
    totalSelectedContacts () {
      return this.contactSelection.contactIds.length
    },
    channels () {
      return [
        {
          text: this.$t('WhatsApp'),
          value: 'whatsapp',
        },
        {
          text: this.$t('Web Chat'),
          value: 'web-chat',
        },
      ]
    },
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'name',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Estado'),
          value: 'status',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getAssistants()
      },
      deep: true,
    },
  },
  mounted () {
    // use in component
    // const hasPer = this.$permissions.hasRole('manager')
    // console.log(hasPer)
    this.loadShowProfiles()
    EventBus.$on('gpt-assistants-load', this.getAssistants)
    EventBus.$on('updateAssistantFromTable', this.updateAssistanInTable)
   },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.CHATBOTS,
        () => {
          this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'ChatBotsSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })

    AiService.getAssistantsSimpleList().then((res) => {
      res.forEach(agent => {
        const agentObject = new Agent(agent)
        this.agents.push(agentObject)
      })
    })
    this.getCurrentUserRoutes()
  },
}
